export const configTemplate = {
  autofocus: true,
  defaultActionOnPaste: 'insert_as_html',
  defaultActionOnPasteFromWord: 'insert_as_html',
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  toolbar: true,
  statusbar: false,
  disablePlugins: ['add-new-line', 'fullsize'],
  removeButtons: ['fullsize'],
  createAttributes: {
    table: {
      border: '1',
      style: 'border-collapse:collapse',
    },
  },
  buttons: [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'spellcheck',
    'superscript',
    'subscript',
    'eraser',
    'ul',
    'ol',
    'outdent',
    'indent',
    'align',
    'paragraph',
    'hr',
    'table',
    'image',
    'link',
    'undo',
    'redo',
  ],
};
